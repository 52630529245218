import ComponentMixin from 'zsui/src/smart/component.m';

// We need a Base class for composition 
class Base extends HTMLElement {
    constructor(...args) {
        const _ = super(...args);
        return _;
    }
}

/**
 * Tooltip ESM component
 * @extends {HTMLElement}
 */
export default class Tooltip extends ComponentMixin(Base) {
    constructor(...args) {
        // Constructor caveat https://github.com/WebReflection/document-register-element/
        const _ = super(...args);
        return _;
    }

    /**
     * Use this method with or instead of the constructor to normalize the flow across browsers. 
     * Call it in the `constructor`, in the `attributeChangeCallback` and in the `connectedCallback` before any other methods or immediately after `document.createElement`
     */
    setup() {
        super.setup();

        // Set prop defaults
        this._setDefaults();

        this._anchor = null;
        this._isHidden = null;
        this._isStartedHidding = null;
        this._isStartedShowing = null;
        this._availablePositions = ['auto', 'top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'left-top', 'left-bottom', 'right-top', 'right-bottom']
        this._availableModes = ['auto', 'manual'];
        this._availableArrowPositions = ['auto', 'none'];

        // Sync Props
        this.syncProp('animateShowWithDuration');
        this.syncProp('animateHideWithDuration');
        this.syncProp('arrowPosition');
        this.syncProp('for');
        this.syncProp('mode');
        this.syncProp('norender');
        this.syncProp('ignoreScrollPosition');
        this.syncProp('offsetX');
        this.syncProp('offsetY');
        this.syncProp('position');
        this.syncProp('handleTransform');
        this.on(['touchend'], this, document);
        this.on(['scroll', 'resize'], this, window);
        this.on(['transitionend']);

        return this;
    }

    _setDefaults() {
        this._animateShowWithDuration = 0;
        this._animateHideWithDuration = 0;
        this._arrowPosition = 'auto';
        this._for = '';
        this._mode = 'auto';
        this._norender = false;
        this._ignoreScrollPosition = true;
        this._offsetX = 0;
        this._offsetY = 0;
        this._position = 'auto';
        this._handleTransform = false;
    }

    /**
     * @type {array} observedAttributes - Observed attributes. Custom Elements API.
     */
    static get observedAttributes() {
        return ['animate-show-with-duration', 'animate-hide-with-duration', 'arrow-position', 'ignore-scroll-position', 'for', 'mode', 'norender', 'offset-x', 'offset-y', 'position', 'handle-transform'];
    }

    /**
     * Returns the datatype of the given property.
     * @param {String} name - Name of the property.
     */
    getPropertyType(name) {
        switch (name) {
            case 'animate-show-with-duration':
            case 'animate-hide-with-duration':
            case 'offset-x':
            case 'offset-y':
                return 'number';
            case 'norender':
            case 'ignore-scroll-position':
            case 'handle-transform':
                return 'boolean';
            default:
                return 'string';
        }
    }

    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        this.syncAttr(name, newValue, this.getPropertyType(name));
    }

    /**
     * Getter for the reference to arrow element. Returns null if the arrowPosition is set to 'none'.
     */
    get arrow() {
        if (this.arrowPosition === 'none') {
            return null;
        }
        var arrow = this.querySelector('zs-tooltip-arrow');
        if (!arrow) {
            arrow = document.createElement('zs-tooltip-arrow');
            this.appendChild(arrow);
        }
        return arrow;
    }

    /**
     * Getter for the reference to Tooltip content element.
     */
    get contentElement() {
        var contentElm = this.querySelector('zs-tooltip-content');
        if (!contentElm) {
            contentElm = document.createElement('zs-tooltip-content');
            this.appendChild(contentElm);
        }
        return contentElm;
    }

    /**
     * Getter for the reference to the anchor element of the Tooltip.
     */
    get anchor() {
        if (this._anchor) { // if anchor was already defined use it
            return this._anchor;
        }

        if (!this.for) { // if user does not specified 'for' selector use parent node for anchor
            this._anchor = this.parentNode || document.body;

            return this._anchor;
        }

        // otherwise try to analize 'for'
        var anchor = document.getElementById(this.for);
        if (!(anchor instanceof HTMLElement)) {
            console.warn('tooltip: Cannot find element by selector specified in "for". Fallback to parent node instead');

            this._anchor = this.parentNode || document.body;

            return this._anchor;
        }

        // if selector was correct - use this element
        this._anchor = anchor;

        return this._anchor;
    }

    /**
     * Sets the anchor for the tooltip.
     */
    set anchor(anchor) {
        this._anchor = anchor;
    }

    get isHidden() {
        return this._isHidden;
    }

    set isHidden(value) {
        this._isHidden = value;
    }

    connectedCallback() {
        super.connectedCallback();
        window.requestAnimationFrame(() => {
            this.render();
        })
    }

    disconnectedCallback() {
        //Check whether the tooltip is being re-arranged or being removed from DOM
        var parent = this.parentElement;
        while (parent && parent != document.body) {
            parent = parent.parentElement;
        }
        if (!parent) {
            window.requestAnimationFrame(() => {
                this.cleanUp();
            })
        }
    }

    /**
     * Renders the Tooltip component.
     */
    render() {
        if (this.norender) {
            return;
        }

        this.fire('beforerender');

        this._prepareArrow();
        this.off(['click', 'mouseenter', 'mouseleave'], this, this.anchor);
        this.on(['click', 'mouseenter', 'mouseleave'], this, this.anchor);
        var scrollParent = this._scrollParent(this.anchor);
        if(scrollParent){
            this.off(['wheel'],this,scrollParent);
            this.on(['wheel'],this,scrollParent);
        }
        this._prepare();
        this.isHidden = true;
        this.style.visibility = 'hidden';
        this.fire('render');
        
        
        return this;
    }

    /**
     * @private
     * Perpares the arrow by applying required classes.
     */
    _prepareArrow() {
        var position = this.arrowPosition;
        if (position === 'none') {
            return this;
        }

        var arrow = this.arrow;
        switch (position) {
            case 'left': arrow.classList.add('left'); break;
            case 'top': arrow.classList.add('top'); break;
            case 'right': arrow.classList.add('right'); break;
            case 'bottom': arrow.classList.add('bottom'); break;
            default: ;
        }

        return this;
    }

    /**
     * @private
     */
    _prepare() {
        this.style.width = 'auto';
        this.style.top = '';
        this.style.left = '';
        this._positioning();
    }


    /**
     * @private
     * Adjusts the position of the Tooltip.
     * @param {String} tryPosition - Optional parameter to try the given position. Useful in case of 'auto' positioning.
     */
    _positioning(tryPosition) {

        //Return if anchor element is currently hidden (incase of tabs and modals)
        if (!this.anchor || (this.anchor.offsetHeight <= 0 && this.anchor.offsetWidth <= 0)) {
            return;
        }
        var rect = this.getBoundingClientRect();
        var anchorRect = this.anchor.getBoundingClientRect();
        this.style.width = rect.width + 'px';

        var anchorTop = anchorRect.top;
        var anchorBottom = anchorRect.bottom;
        var anchorLeft = anchorRect.left;
        var anchorRight = anchorRect.right;
        if (this.handleTransform) {
            var transformedParentEle = this.getTransformedParent(this.anchor);
            if (transformedParentEle) {
                anchorLeft = anchorLeft - transformedParentEle.getBoundingClientRect().x + transformedParentEle.scrollLeft;
                anchorTop = anchorTop - transformedParentEle.getBoundingClientRect().y + transformedParentEle.scrollTop;
                anchorRight = anchorRight - transformedParentEle.getBoundingClientRect().x + transformedParentEle.scrollLeft;
                anchorBottom = anchorBottom - transformedParentEle.getBoundingClientRect().y + transformedParentEle.scrollTop;
            }
        }

        var offsetY = this.offsetY;
        var offsetX = this.offsetX;

        // Normalize
        this.style.top = '';
        this.style.bottom = '';
        this.style.right = '';
        this.style.left = '';

        var arrow = this.arrow;
        if (arrow) {
            arrow.style.top = '';
            arrow.style.left = '';
        }

        this.classList.remove('top');
        this.classList.remove('bottom');
        this.classList.remove('right');
        this.classList.remove('left');

        var anchorCenterX = anchorLeft + anchorRect.width / 2;
        var anchorCenterY = anchorTop + anchorRect.height / 2;

        var space = 25;
        var position = tryPosition || this.position;
        var newLeft, newTop, bestPosition;

        switch (position) {
            case 'top':
                this.style.top = (anchorTop - rect.height - offsetY) + 'px';
                this.style.left = (anchorCenterX - rect.width / 2) + 'px';
                this.classList.add('top');
                break;

            case 'bottom':
                this.style.top = (anchorBottom + offsetY) + 'px';
                this.style.left = (anchorCenterX - rect.width / 2) + 'px';
                this.classList.add('bottom');
                break;

            case 'left':
                this.style.left = (anchorLeft - rect.width - offsetX) + 'px';
                this.style.top = (anchorCenterY - rect.height / 2) + 'px';
                this.classList.add('left');
                break;

            case 'right':
                this.style.left = (anchorRight + offsetX) + 'px';
                this.style.top = (anchorCenterY - rect.height / 2) + 'px';
                this.classList.add('right');
                break;

            case 'top-left':
                newLeft = (anchorLeft - space);
                this.style.top = (anchorTop - rect.height - offsetY) + 'px';
                this.style.left = newLeft + 'px';
                this.classList.add('top');
                if (arrow) {
                    arrow.style.left = anchorCenterX - newLeft + 'px';
                }
                break;

            case 'top-right':
                newLeft = (anchorRight - rect.width + space);
                this.style.top = (anchorTop - rect.height - offsetY) + 'px';
                this.style.left = newLeft + 'px';
                this.classList.add('top');
                if (arrow) {
                    arrow.style.left = anchorCenterX - newLeft + 'px';
                }
                break;

            case 'bottom-left':
                newLeft = (anchorLeft - space);
                this.style.top = (anchorBottom + offsetY) + 'px';
                this.style.left = (anchorLeft - space) + 'px';
                this.classList.add('bottom');
                if (arrow) {
                    arrow.style.left = anchorCenterX - newLeft + 'px';
                }
                break;

            case 'bottom-right':
                newLeft = (anchorRight - rect.width + space);
                this.style.top = (anchorBottom + offsetY) + 'px';
                this.style.left = newLeft + 'px';
                this.classList.add('bottom');
                if (arrow) {
                    arrow.style.left = anchorCenterX - newLeft + 'px';
                }
                break;

            case 'left-top':
                newTop = (anchorTop - space);
                this.style.left = (anchorLeft - rect.width - offsetX) + 'px';
                this.style.top = newTop + 'px';
                this.classList.add('left');
                if (arrow) {
                    arrow.style.top = anchorCenterY - newTop + 'px';
                }
                break;

            case 'left-bottom':
                newTop = (anchorBottom - rect.height + space);
                this.style.left = (anchorLeft - rect.width - offsetX) + 'px';
                this.style.top = newTop + 'px';
                this.classList.add('left');
                if (arrow) {
                    arrow.style.top = anchorCenterY - newTop + 'px';
                }
                break;

            case 'right-top':
                newTop = (anchorTop - space);
                this.style.left = (anchorRight + offsetX) + 'px';
                this.style.top = newTop + 'px';
                this.classList.add('right');
                if (arrow) {
                    arrow.style.top = anchorCenterY - newTop + 'px';
                }
                break;

            case 'right-bottom':
                newTop = (anchorBottom - rect.height + space);
                this.style.left = (anchorRight + offsetX) + 'px';
                this.style.top = newTop + 'px';
                this.classList.add('right');
                if (arrow) {
                    arrow.style.top = anchorCenterY - newTop + 'px';
                }
                break;

            case 'auto':
                bestPosition = this.getBestPosition(rect, anchorRect, anchorCenterX, anchorCenterY);
                this._positioning(bestPosition);
                break;
        }


        /**
         * Browser tries to optimize size of the element
         * In some cases changing of the position of element will affect this optimization
         * Sometimes it causes side affects (like continues "moving" of tooltip with every new showing)
         * To prevent that let set a fixed width
         */


    }

    /**
     * Get the transformed parent element
     * @param {HTMLElement} el Reference to HTML element whose transformed parent is to be found.
     */
    getTransformedParent(el) {
        while (el.parentElement !== document.body) {
            if (getComputedStyle(el.parentElement).transform !== 'none') {
                return el.parentElement;
            }
            el = el.parentElement;
        }
        return false;
    }
    /**
     * Gets the best position for the Tooltip to render in case of 'auto' position based on the available space around the anchor element.
     * @param {Object} rect - Object containing the bounding client values for the Tooltip element.
     * @param {Object} anchorRect - Object containing the bounding client values for the anchor element.
     * @param {Number} anchorCenterX - x-coordinate value of the center of the anchor element.
     * @param {Number} anchorCenterY - y-coordinate value of the center of the anchor element.
     */
    getBestPosition(rect, anchorRect, anchorCenterX, anchorCenterY) {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var bestPosition = '';
        var scrollParent = this._scrollParent(this.anchor);
        scrollParent = scrollParent == document.body ? (this.ownerDocument || document) : scrollParent;
        var rectCenterX = rect.width / 2;
        var rectCenterY = rect.height / 2;
        var anchorCenterXFromRight = ((window.innerWidth - anchorRect.right) + anchorRect.width / 2);
        var anchorCenterYFromBottom = ((window.innerHeight - anchorRect.bottom) + anchorRect.height / 2);

        // Check all the possibilities
        var isTopPossible = rect.height <= (anchorRect.top + ((!this.ignoreScrollPosition) && (scrollParent == document ? (isSafari ? scrollParent.body.scrollTop : scrollParent.documentElement.scrollTop) : scrollParent.scrollTop)));
        var isBottomPossible = rect.height <= (window.innerHeight - anchorRect.bottom);
        var isLeftPossible = rect.width <= (anchorRect.left + ((!this.ignoreScrollPosition) && (scrollParent == document ? (isSafari ? scrollParent.body.scrollLeft : scrollParent.documentElement.scrollLeft) : scrollParent.scrollLeft)));
        var isRightPossible = rect.width <= (window.innerWidth - anchorRect.right);

        // Try for the natural positions first
        if (isTopPossible || isBottomPossible) {
            bestPosition = isTopPossible ? 'top' : 'bottom';
            if ((anchorCenterX > rectCenterX) && (anchorCenterXFromRight > rectCenterX)) {
                return bestPosition;
            }
        } else if (isLeftPossible || isRightPossible) {
            bestPosition = isRightPossible ? 'right' : 'left';
            if ((anchorCenterY > rectCenterY) && (anchorCenterYFromBottom > rectCenterY)) {
                return bestPosition;
            }
        }

        // Try other edge positions such as top-left, top-right, bottom-left, bottom-right, etc.
        if (isTopPossible || isBottomPossible) {
            if ((anchorCenterX < rectCenterX) && (anchorCenterXFromRight > rect.width)) {
                return bestPosition + '-left';
            } else if ((anchorCenterX > rectCenterX) && (anchorCenterX > rect.width)) {
                return bestPosition + '-right';
            }
        } else if (isLeftPossible || isRightPossible) {
            if ((anchorCenterY < rectCenterY) && (anchorCenterYFromBottom > rect.height)) {
                return bestPosition + '-top';
            } else if ((anchorCenterY > rectCenterY) && (anchorCenterY > rect.height)) {
                return bestPosition + '-bottom';
            }
        }

        // Cannot find the best position for the tooltip. It may not be fully visible. Go with the default one.
        return 'bottom';
    }


    /**
     * Get the closest ancestor element that is scrollable. Adapted from jQuery UI plugin.
     * @private
     * @param {HTMLElement} ele Reference to HTML element whose scrollable parent is to be found.
     * @param {boolean} includeHidden Indicates whether to include hidden parent too.
     */
    _scrollParent(ele, includeHidden) {
        var position = window.getComputedStyle(ele).position,
            excludeStaticParent = position === "absolute",
            overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

        var parent = ele, scrollParent = null;
        
        while (parent.parentElement != null) {
            parent = parent.parentElement;
            if (excludeStaticParent && window.getComputedStyle(parent).position === "static") {
                continue;
            }
            if (overflowRegex.test(window.getComputedStyle(parent).overflow + window.getComputedStyle(parent).overflowY + window.getComputedStyle(parent).overflowX)) {
                scrollParent = parent;
                break;
            }
        }
        
        return position === "fixed" || !scrollParent ?
            ele.ownerDocument || document :
            scrollParent;
    }

    /**
     * @private
     */
    _finalizeAfterShowHide() {
        if (this._isStartedHidding) {
            this.isHidden = true;
            this._isStartedHidding = false;
            this.style.visibility = 'hidden';
            this.style.opacity = 0;
            this.fire('hide');
        } else {
            this.isHidden = false;
            this._isStartedShowing = false;
            this.style.visibility = 'visible';
            this.style.opacity = 1;
            this.fire('show');
        }

        return this;
    }


    /**
     * Fires when anchor was activated in some way (clicked, hovered etc)
     * 
     * If tooltip is in "auto" mode it shows tooltip
     * 
     * @chainable
     * @private
     * 
     * @param {Event} e
     */
    _anchorActivated(e) {
        if (this.mode != 'auto') {
            return this;
        }

        this.show();

        return this;
    }

    /**
     * Fires when anchor was deactivated in some way (mouseleaved etc)
     * 
     * If tooltip is in "auto" mode it hides tooltip
     * 
     * @chainable
     * @private
     * 
     * @param {Event} e
     */
    _anchorDeactivated(e) {

        if (this.mode != 'auto') {
            return this;
        }
        if (this.isHidden) {
            return this;
        }
        this.hide();

        return this;
    }

    /**
     * Show tooltip
     * 
     * @chainable
     */
    show() {
        this.fire('beforeshow');

        this._isStartedShowing = true;
        this._isStartedHidding = false;
        this.style.visibility = 'visible';

        this.style.transition = 'none';
        if (this.animateShowWithDuration > 0) {
            this.style.transition = 'opacity ' + (this.animateShowWithDuration / 1000).toFixed(2) + 's';
            this.style.opacity = 1;
        } else {
            this._finalizeAfterShowHide();
        }

        this._prepare();

        return this;
    }

    /**
     * Hide tooltip
     * 
     * @chainable
     */
    hide() {
        this.fire('beforehide');

        this._isStartedShowing = false;
        this._isStartedHidding = true;

        this.style.transition = 'none';
        if (this.animateHideWithDuration > 0) {
            this.style.transition = 'opacity ' + (this.animateHideWithDuration / 1000).toFixed(2) + 's';
            this.style.opacity = 0;
        } else {
            this._finalizeAfterShowHide();
        }

        return this;
    }

    /**
     * Sets content of tooltip
     * 
     * @chainable
     * 
     * @param {String} content
     */
    setContent(content) {
        this.contentElement.innerHTML = content;
    }

    /**
     * Returns current content of the tooltip
     * 
     * @chainable
     * 
     * @returns {String}
     */
    getContent() {
        return this.contentElement.innerHTML;
    }

    /**
     * Destroy tooltip
     * 
     * @chainable
     */
    destroy() {
        this.fire('beforedestroy');
        this.cleanUp();
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
        this.fire('destroy');
        return this;
    }


    /**
     * Clean up the element.
     * 
     * @chainable
     */
    cleanUp() {
        var scrollParent = this._scrollParent(this.anchor);
        while (this.firstChild && this.firstChild.parentNode) {
            this.firstChild.parentNode.removeChild(this.firstChild);
        }

        if (this.arrow) {
            if (this.arrow.parentNode) {
                this.arrow.parentNode.removeChild(this.arrow);
            }
        }

        this.off(['scroll', 'resize','wheel'], this, window);

        if(scrollParent){
            this.off(['wheel'],this,scrollParent);
        }

        return this;
    }

    onwheel(e)
    {
        this._anchorDeactivated(e);
    }

    onclick(e) {
        if (!this.isHidden) {
            return
        }
        this._anchorActivated(e);
    }

    onmouseenter(e) {
        
        this._anchorActivated(e);
    }

    onmouseleave(e) {
        this._anchorDeactivated(e);
    }

    ontouchend(e) {

        if (this.isHidden) {
            return
        }

        if (this._isStartedShowing) {
            return
        }

        if (this._isStartedHidding) {
            return
        }

        this._anchorDeactivated(e);
    }

    onscroll(e) {
        this._anchorDeactivated(e);
    }


    onresize(e) {
        this._prepare(e);
    }

    ontransitionend(e) {
        if (e.propertyName !== 'opacity') {
            return this;
        }

        return this._finalizeAfterShowHide();
    }
}

Tooltip.is = 'zs-tooltip';