import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DomSanitizerConstants } from '../constants/security-attacks-constants';

@Pipe({
  name: 'domSanitizerPipe'
})
export class DomSanitizerPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  public transform(value: any, type: number, action: string): any {
    if (action === DomSanitizerConstants.SANITIZE) {
      return this.sanitizer.sanitize(type, value);
    }

  }

}
