import { RouterModule, Routes } from '@angular/router';

import { AppRoles } from './constants';
import { AuthGuard } from './auth.guard';
import { NavigationTabs } from './shared/constants';
import { NgModule } from '@angular/core';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'selfserve',
    pathMatch: 'full',
    data: {
      expectedRoles: [AppRoles.MasterDataSteward, AppRoles.ADMIN],
      navigationTabName: [NavigationTabs.HOME],
    },
  },
  {
    path: 'selfserve',
    loadChildren: () =>
      import('./self-serve/self-serve.module').then((m) => m.SelfServeModule),
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [AppRoles.MasterDataSteward, AppRoles.ADMIN],
      navigationTabName: [NavigationTabs.MASTER],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
