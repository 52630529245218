import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  common_labels,
  revo_core_labels,
} from 'src/app/shared/constants/ui_labels_translation_mapping';

import { FormValidationService } from '../form-validation-service/form-validation-service';
import RDHSelect from '../zsui-component-extensions/RDHSelect.m.js';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

RDHSelect.register('zs-multiselect', 'p');
declare var $: any;
@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectDropdownComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() dataSource: any;
  @Input() labelInverse = false;
  @Input() isMultiple: boolean;
  @Input() isSelectAllVisible: boolean;
  @Input() isCheckBoxVisible: boolean;
  @Input() isDataSourceTwoWayBindingRequired: boolean;
  @Input() minOptionsForSearch: number;
  @Input() isRequired: Boolean;
  @Input() inErrorState: Boolean;
  @Input() emptyText: string;
  @Input() defaultText: string;
  @Input() disabled: Boolean;
  @Input() label: string;
  @Input() isLabelHidden: string;
  @Input() customStyle: any;
  @Input() touched = false;
  @Input() selected: any;
  @Input() explicitIdentifier: string;
  @Input() containsGroup: Boolean;
  @Input() flippable = false;
  @Input() hideOnScroll = false;
  @Input() elementId: string;

  @Output() selectedOptionsChange = new EventEmitter();
  @Output() inErrorStateChange = new EventEmitter(true);
  @Input() formValidationCallbackFunction: Function;

  @ViewChild('zsMultiSelect') zsMultiSelect: ElementRef;

  @HostBinding('class.zs-error') public inputClass = '';

  formValidationSubscription: Subscription;
  iterableDiffer: any;
  objDiffer = {};
  config: any;
  selectedOptions = [];
  errorClass = 'zs-error';
  errorMessage = '';
  selectedOptionString = '';
  translated_labels: any;
  validation_labels = revo_core_labels.VALIDATION_LABELS;
  default: any;
  source: any;
  selectedValues = [];
  textOnHover = '';
  noRecordsText: string;
  commonLabels = common_labels;

  constructor(
    private _formValidationService: FormValidationService,
    private _translate: TranslateService
  ) {
    this.translated_labels = this._translate.instant([
      this.validation_labels.VALUE_REQUIRED,
      this.commonLabels.NO_ITEMS_FOUND_FOR_SEARCH,
    ]);
    this.noRecordsText =
      this.translated_labels[this.commonLabels.NO_ITEMS_FOUND_FOR_SEARCH];
  }

  ngAfterViewInit() {
    if (this.emptyText) {
      this.zsMultiSelect.nativeElement.emptyText = this.emptyText;
    }
  }

  ngOnInit() {
    if (this.dataSource) {
      this.dataSource.forEach((element) => {
        if (element['isSelected'] === undefined) {
          element['isSelected'] = false;
        }
      });
    }
  }

  validate() {
    if (this.checkForRequiredField()) {
      this.inputClass = this.errorClass;
      this.errorMessage =
        this.translated_labels[this.validation_labels.VALUE_REQUIRED];
      this.inErrorState = true;
    } else {
      this.reset();
    }

    this.inErrorStateChange.emit(this.inErrorState);
  }

  reset() {
    this.inputClass = '';
    this.errorMessage = '';
    this.inErrorState = false;
  }

  checkForRequiredField() {
    if (this.isRequired !== undefined && this.isRequired) {
      if (
        this.selectedOptions === undefined ||
        (this.selectedOptions !== undefined &&
          this.selectedOptions.length === 0) ||
        this.selectedOptions[0].value === ''
      ) {
        return true;
      }
      return false;
    }
  }

  onChange() {
    this.handleMultiSelectClickEvent();
    this.validate();
    this.textOnHover = this.zsMultiSelect.nativeElement.getOverlayText();
  }

  beforeClose() {
    this.touched = true;
    this.inErrorStateChange.emit(this.inErrorState);
  }

  onGroupOptionSelect(selectedValues) {
    this.selectedOptions = [];
    for (const category of this.dataSource) {
      let selectedCategory = null;
      for (const subCategory of category.options) {
        if (this.isDataSourceTwoWayBindingRequired) {
          subCategory.isSelected = false;
        }
        const index = selectedValues.indexOf(subCategory.value.toString());
        if (index > -1) {
          if (this.isDataSourceTwoWayBindingRequired) {
            subCategory.isSelected = false;
          }
          if (selectedCategory === null) {
            selectedCategory = Object.assign({}, category);
            selectedCategory.options = [];
            selectedCategory.options.push(subCategory);
          } else {
            selectedCategory.options.push(subCategory);
          }
        }
      }
      if (selectedCategory !== null) {
        this.selectedOptions.push(selectedCategory);
      }
    }
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  handleMultiSelectClickEvent() {
    if (this.isMultiple === true) {
      const selectedValues = this.zsMultiSelect.nativeElement.getValue();
      this.selectedOptions = [];
      if (this.isDataSourceTwoWayBindingRequired) {
        this.unSelectAllDataValues();
      }
      if (selectedValues !== '') {
        if (this.containsGroup) {
          this.onGroupOptionSelect(selectedValues);
        } else {
          this.getAllDataSourceItemByValue(selectedValues);
        }
      }
      this.selectedOptionsChange.emit(this.selectedOptions);
    } else {
      const selectedValue = this.zsMultiSelect.nativeElement.getValue();
      if (
        this.dataSource != null &&
        this.dataSource.length > 0 &&
        selectedValue != null
      ) {
        if (this.containsGroup) {
          this.onGroupOptionSelect([selectedValue]);
        } else {
          this.setSelectedForSingleSelect(selectedValue);
        }
      }
    }
  }

  getAllDataSourceItemByValue(selectedValues) {
    const valueMapping = {};
    selectedValues.forEach((value) => {
      if (valueMapping.hasOwnProperty(value)) {
        valueMapping[value] = valueMapping[value] + 1;
      } else {
        valueMapping[value] = 1;
      }
    });
    this.selectedOptions = [];
    if (this.dataSource != null && this.dataSource.length > 0) {
      this.dataSource.forEach((data) => {
        const keyToFind = data.value.toString();
        if (
          valueMapping.hasOwnProperty(keyToFind) &&
          valueMapping[keyToFind] > 0
        ) {
          if (this.isDataSourceTwoWayBindingRequired) {
            data.isSelected = true;
          }
          valueMapping[keyToFind] = valueMapping[keyToFind] - 1;
          this.selectedOptions.push(data);
        }
      });
    }
  }

  unSelectAllDataValues() {
    if (this.dataSource != null) {
      this.dataSource.forEach((e) => {
        e.isSelected = false;
      });
    }
  }

  getDataSourceItemByValue(dataSourceItemName) {
    if (this.dataSource != null && this.dataSource.length > 0) {
      for (let i = 0; i < this.dataSource.length; i++) {

        if (this.dataSource[i].value == dataSourceItemName) {
          return this.dataSource[i];
        }
      }
    }
  }

  setSelectedForSingleSelect(selectedValue: any) {
    const selectedDataSourceItem = this.getDataSourceItemByValue(selectedValue);
    if (selectedDataSourceItem != null) {
      if (this.isDataSourceTwoWayBindingRequired) {
        this.unSelectAllDataValues();
        selectedDataSourceItem.isSelected = true;
      }
      this.selectedOptions = [];
      this.selectedOptions.push(selectedDataSourceItem);
      this.selectedOptionsChange.emit(this.selectedOptions);
    } else {
      this.selectedOptions = [];
      this.selectedOptionsChange.emit(this.selectedOptions);
    }
  }

  destroy() {
    setTimeout(() => {
      this.zsMultiSelect.nativeElement.destroy();
    });
  }

  configure() {
    this.source = [];
    if (this.defaultText && this.default) {
      this.source.push(this.default);
    }
    this.selectedValues = [];
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((element) => {
        this.source.push(element);
        if (this.containsGroup) {
          element['options'].forEach((subCategory) => {
            if (subCategory.isSelected) {
              this.selectedValues.push(subCategory['value']);
            }
          });
        } else if (
          element['isSelected'] === true ||
          this.selected === element['value']
        ) {
          this.selectedValues.push(element['value']);
        }
      });
    }
    setTimeout(() => {
      this.zsMultiSelect.nativeElement.update();
      this.onChange();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.defaultText) {
      this.default = {
        displayLabel: this.defaultText,
        value: '',
        isSelected: false,
      };
    }

    if (
      changes['dataSource'] &&
      this.dataSource &&
      this.dataSource.length > 0
    ) {
      this.configure();
    }
    if (changes['touched']) {
      this.validate();
    }

    if (changes['selected'] && this.selected) {
      this.configure();
    }

    // As formValidationCallbackFunction dynamically changes in case of BRM lookup fields
    if (changes['formValidationCallbackFunction']) {
      if (
        changes['formValidationCallbackFunction'].currentValue === false &&
        this.formValidationSubscription
      ) {
        this.formValidationSubscription.unsubscribe();
      } else if (changes['formValidationCallbackFunction'].currentValue) {
        this.formValidationSubscription = this._formValidationService
          .getMessage()
          .subscribe((message) => {
            this.touched = true;
            this.validate();
            this.formValidationCallbackFunction(this.inErrorState);
          });
      }
    }
  }

  ngOnDestroy() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription.unsubscribe();
    }
  }
}
