import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { common_labels, revo_core_labels, ingest_extract_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

import FieldValidation from '../zsui-component-extensions/customActionFieldValidation.m.js';
import { FormValidationService } from '../form-validation-service/form-validation-service';
import { GlobalParameterConstants } from 'src/app/shared/constants';
import { Subscription } from 'rxjs';
import { ToasterDataService } from '../toaster-service/toaster-data.service';
import { ToasterTypeConstants } from 'src/app/shared/constants/toaster-constants';
import { TranslateService } from '@ngx-translate/core';

FieldValidation.register('zs-field', 'p');

@Component({
  selector: 'app-zs-textbox',
  templateUrl: './zs-textbox.component.html',
  styleUrls: ['./zs-textbox.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ZsTextboxComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() hint: string;
  @Input() infoLabel = '';
  @Input() labelInverse = false;
  @Input() changeDetect = false;
  @Input() placeholder: string;
  @Input() hideLabel: string;
  @Input() regex: string;
  @Input() maxCharLength: Number;
  @Input() required: boolean;
  @Input() showOptionalText: boolean;
  @Input() inErrorState: Boolean;
  @Input() modelValue: string;
  @Input() validateFunction: Function;
  @Input() validateFunctionArgs = [];
  @Input() blurFunction: Function;
  @Input() blurFunctionArgs = [];
  @Input() inputType: string;
  @Input() customStyle: object;
  @Input() enableSearch: string;
  @Input() disabled: Boolean;
  @Input() regexErrorMessage = '';
  @Input() touched = false;
  @Input() infoIconMessage: string;
  @Input() formValidationCallbackFunction: Function;
  @Input() enableBlurFunctionOnChange = false;
  @Input() showTooltip = true;
  @Input() autoselectStyle;
  @Input() dataSource: any;
  @Input() clearPasswordOnClick = true;
  @Input() showLabelTooltip: boolean = false;
  @Input() hintEllipsisConstant:number = 10;
  @Input() emptyStringAllowed: boolean;
  @Output() modelValueChange = new EventEmitter(true);
  @Output() modelValueDiff = new EventEmitter(true);
  @Output() inErrorStateChange = new EventEmitter(true);
  @Output() validated = new EventEmitter(true);
  @Output() searchClear = new EventEmitter();


  autocompleteField = false;
  searchText = '';
  final =  '';
  revoValidationlabels = revo_core_labels;
  globalParameterFlag = false;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('actionField') actionField: ElementRef;

  formValidationSubscription: Subscription;

  errorClass = 'zs-error';
  previousValue;
  currentValue;
  errorMessage = '';
  helperText = '';

  translated_labels: any;
  validation_labels = revo_core_labels.VALIDATION_LABELS;
  error_labels = ingest_extract_labels.EXTRACTION_TRANSACTION_INFO;
  globalParamLabels = GlobalParameterConstants;
  commonlabels = common_labels;

  @HostBinding('class.zs-error') public inputClass = '';

  constructor(private _formValidationService: FormValidationService,
    private _translate: TranslateService, private _toasterDataService: ToasterDataService) {
    this.translated_labels = this._translate.instant(
      [
        this.validation_labels.VALUE_REQUIRED,
        this.validation_labels.LIMIT_EXCEED,
        this.validation_labels.ILLEGAL_CHARS,
        this.validation_labels.MISMATCH_IN_PARANTHESIS,
        this.validation_labels.SEMICOLON_NOT_ALLOWED,
        this.validation_labels.GLOBAL_PARAMETER_PASSWORD_EXCEPTION,
        this.error_labels.REGEX_ERROR_MESSAGE,
      ]
    );
  }

  ngOnInit() {
    if (this.modelValue) {
      this.touched = true;
      this.textboxValidationForGp(this.modelValue);
    }

    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription = this._formValidationService.getMessage().subscribe(
        message => {
          this.touched = true;
          this.validate(null, true, false);
          this.formValidationCallbackFunction(this.inErrorState);
        }
      );
    }
    if (this.showOptionalText === undefined) {
      this.showOptionalText = true;
    }
  }

  ngAfterViewInit() {
    if (!this.changeDetect) {
      this.actionField.nativeElement.oninput = this.onInput.bind(this);
    } else {
      this.actionField.nativeElement.onchange = this.onInput.bind(this);
    }
    this.actionField.nativeElement.checkValidity = this.checkValidityOverride.bind(this);
    this.validate(null, true, this.enableBlurFunctionOnChange);
    if (this.touched) {
      this.blurFunctionOnChange();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['modelValue']) {
      this.previousValue = changes['modelValue'].previousValue;
      this.currentValue = changes['modelValue'].currentValue;
    }
    if ((changes['modelValue'] && changes['modelValue'].currentValue !== undefined && changes['modelValue'].currentValue !== null
      && !changes['modelValue'].firstChange) || (changes['touched'] && changes['touched'].currentValue &&
       !changes['touched'].firstChange)) {
      this.touched = true;
      this.validate(null, false, this.enableBlurFunctionOnChange);
    } else if (changes['required'] && !changes['required'].firstChange) { // check if its required anymore
      this.validate(null, true, false);
    }
  }

  checkValidityOverride() {
    // added this function so as to override the default zsui code behaviour
    // which was changing the value of "this.actionField.nativeElement.value"
    return;
  }

  onClick() {
    if (this.inputType !== undefined && this.clearPasswordOnClick && this.inputType.toLowerCase() === 'password') {
      this.actionField.nativeElement.value = '';
      this.modelValue = '';
      this.modelValueChange.emit(this.modelValue);
    } else {
      this.modelValue = this.modelValue || '';
    }
    this.touched = true;
  }

  validate(event = null, emitChangesFlag = true, shouldCallOnBlur = false) {
    this.actionField.nativeElement.invalid = false; // added to resolve the issue of incorrect error message being emitted.
    this.inputClass = '';
    this.errorMessage = '';
    this.inErrorState = false;

    if (this.checkForCharLimit()) {
      this.inputClass = this.errorClass;
      this.errorMessage = this.translated_labels[this.validation_labels.LIMIT_EXCEED];
      this.inErrorState = true;
      if (!this.actionField.nativeElement.invalid) {
        this.actionField.nativeElement.invalid = true;
        this.actionField.nativeElement.showMessage(this.errorMessage);
      }
    } else if (this.touched && this.checkForRequiredField()) {
      this.inputClass = this.errorClass;
      this.errorMessage = this.translated_labels[this.validation_labels.VALUE_REQUIRED];
      this.inErrorState = true;
      if (!this.actionField.nativeElement.invalid) {
        this.actionField.nativeElement.invalid = true;
        this.actionField.nativeElement.showMessage(this.errorMessage);
      }
    } else if (this.touched && this.checkForRegexValidation()) {
      this.inputClass = this.errorClass;
      if (this.regexErrorMessage === '') {
        this.errorMessage = this.translated_labels[this.validation_labels.ILLEGAL_CHARS];
      } else if (this.regexErrorMessage === 'Please enter alpha-numeric values or underscore.') {
        this.errorMessage = this.translated_labels[this.error_labels.REGEX_ERROR_MESSAGE];
      } else {
        this.errorMessage = this.regexErrorMessage;
      }
      this.inErrorState = true;
      if (!this.actionField.nativeElement.invalid) {
        this.actionField.nativeElement.invalid = true;
        this.actionField.nativeElement.showMessage(this.errorMessage);
      }
    } else if (this.touched && this.validateFunction) {
      let message: any;
      if (this.validateFunctionArgs && this.validateFunctionArgs.length > 0) {
        message = this.validateFunction(this.modelValue, this.validateFunctionArgs);
      } else {
        message = this.validateFunction(this.modelValue);
      }
      this.checkForError(message);
    } else if (this.blurFunction && shouldCallOnBlur) {
      this.blurFunctionOnChange();
      this.inErrorState = (!this.modelValue && this.required) || this.inErrorState;
    } else {
      this.resetTextBox();
      this.inErrorState = (!this.modelValue && this.required);
    }
    if (emitChangesFlag) {
      this.inErrorStateChange.emit(this.inErrorState);
      this.modelValueChange.emit(this.modelValue);
      this.validated.emit(true);
    }
  }

  checkForCharLimit() {
    /*return (this.modelValue?.length > this.maxCharLength && !this.globalParameterFlag) ? true : false;*/
    return (this.modelValue?.length > this.maxCharLength.valueOf() && !this.globalParameterFlag) ? true : false;

  }

  checkForRequiredField() {
    if (this.required !== undefined && this.required) {
      if (this.modelValue === undefined || this.modelValue.length === 0 ||
        (this.modelValue !== undefined && this.modelValue !== null && (!this.emptyStringAllowed && this.modelValue.trim().length === 0) )) {
        return true;
      }
      return false;
    }
  }

  checkForRegexValidation() {
    return (this.regex !== undefined && !new RegExp(this.regex).test(this.modelValue) && !this.globalParameterFlag) ? true : false;
  }

  resetTextBox() {
    this.inputClass = '';
    this.errorMessage = '';
    this.inErrorState = false;

    if (this.actionField) {
      // removed && this.actionField.nativeElement.invalid from if
      // statement to fix the issue of value required error being emitted after putting in a value.
      this.actionField.nativeElement.invalid = false;
      this.actionField.nativeElement.hideMessage();
    }
  }

  blurFunctionOnChange() {
    if (this.blurFunction) {
      if (this.errorMessage === '') {
        if (this.blurFunctionArgs && this.blurFunctionArgs.length > 0) {
          this.errorMessage = this.blurFunction(this.modelValue, this.blurFunctionArgs);
        } else {
          this.errorMessage = this.blurFunction(this.modelValue);
        }
      }
      this.checkForError(this.errorMessage);
    }
  }

  focusOutEvent(event) {
    this.blurFunctionOnChange();
    this.autocompleteField = false;
  }

  checkForError(errorMessage) {
    if (errorMessage && errorMessage.length > 0) {
      this.inputClass = this.errorClass;
      this.errorMessage = errorMessage;
      this.inErrorState = true;

      if (this.actionField !== undefined && !this.actionField.nativeElement?.invalid) {
        this.actionField.nativeElement.invalid = true;
        setTimeout(() => this.actionField.nativeElement.showMessage(this.errorMessage));
      }
      this.inErrorStateChange.emit(this.inErrorState);
    } else {
      this.resetTextBox();
    }
  }

  ngOnDestroy() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription.unsubscribe();
    }
  }

  onInput($event) {
      this.touched = true;
      this.modelValue = $event.target.value;
      this.modelValueDiff.emit({
        'previousValue': this.currentValue,
        'currentValue':  this.modelValue
      });
      this.validate(null, true, this.enableBlurFunctionOnChange);
      if (this.enableSearch && this.modelValue === '') {
        this.searchClear.emit(true);
      }
  }

  showHelperText() {
    this.helperText = this.infoIconMessage;
  }

  scroll(data) {
    data.target._scrollParent(data.target.anchor).addEventListener('scroll', function(e) {
      data.target.onscroll(e);
  });
  }


  onTextChange(val) {
    if (val && val.includes('$')) {
      this.textboxValidationForGp(val);
      const splitedText = val.split('$');
      this.searchText = splitedText.pop();
      const lastValue = val.slice(-1);
      if (lastValue === '$') {
        this.final = val.slice(0, -1);
        this.autocompleteField = true;
      } else {
        this.final = splitedText.join('$');
      }
    } else {
      this.globalParameterFlag = false;
      this.autocompleteField = false;
    }
  }

  selectValueFromMenu(menu: any) {
    if (menu) {
      this.modelValue = this.final + menu[this.globalParamLabels.DISPLAY_LABEL];
      this.modelValueChange.emit(this.modelValue);
      if (this.inputType === this.globalParamLabels.PASSWORD &&  menu[this.globalParamLabels.TYPE] !== this.globalParamLabels.PASSWORD) {
        this._toasterDataService.addMessageToToaster(this.translated_labels[this.validation_labels.GLOBAL_PARAMETER_PASSWORD_EXCEPTION],
          ToasterTypeConstants.WARNING
        );
      }
    }
    this.autocompleteField = false;
    if (this.touched) {
      this.validate(true);
    }
    this.touched = true;
  }
  menuOpened() {
    this.menu.nativeElement.focus();
  }

  textboxValidationForGp(value) {
    this.globalParameterFlag = (this.dataSource && value.includes('$')) ? true : false;
  }
}
