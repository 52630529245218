import Select from '../../../assets/zsui/select.m';

export default class OCSelect extends Select {
    constructor(...args) {

        const _ = super(...args);
        return _;
    }

    /**
     * Add options property 
     */
    static get properties() {
        return {
            labelKey: {
                type: String, default: 'text'
            },
            valueKey: {
                type: String, default: 'value'
            },
            options: {
                type: Array, default: [], onChange: function (oldValue, newValue) {
                    if (this.selectEl && newValue) {
                        this.updateSelect(this.selectEl, newValue);
                        this.render();
                    }
                }

            },

            selectedValues: {
                type: Array, default: [], onChange: function (oldValue, newValue) {
                    if (this.selectEl && this.options) {
                        this.updateSelect(this.selectEl, this.options);
                        this.render();
                    }
                }
            }
        }
    }

    propertyChangedCallback(name, oldValue, newValue) {
        if (name === 'multiple' && this.selectEl) {
            this.selectEl.multiple = newValue;
        } else {
            super.propertyChangedCallback(name, oldValue, newValue)
        }
    }



    updateSelect(select, options) {
        if (this.multiple) {
            select.setAttribute('multiple', '');
        } else {
            select.removeAttribute('multiple');
        }
        select.innerHTML = this.templateOptions(this._labelKey, this._valueKey, options);
    }

    templateOption(label, value, option) {
        return `${option.options ?
            `<optgroup label='${option[label]}'>${this.templateOptions(label, value, option.options)}</optgroup>` :
            `<option ${this.checkSelectedValues(option[value]) ? 'selected ' : ''} ${option.placeholder ? 'placeholder ' : ''} title='${option[label]}' value='${option[value]}'>${option[label]}</option>`}`;
    }

    checkSelectedValues(id) {
        if (!Array.isArray(this._selectedValues)) { return false }
        return this._selectedValues.includes(id);
    }

    templateOptions(label, value, options) {
        if (!Array.isArray(options)) { return ''; }
        return options.reduce((html, option) => html + this.templateOption(label, value, option), '');
    }

    renderSelect() {
        var el = this.selectEl;
        if (!el) {
            el = document.createElement('select');

            this.appendChild(el);
            this.updateSelect(el, this.options);
        }
    }

    render() {
        if (this.options) {
            this.renderSelect();
        }
        super.render();
    }

    get selectEl() {
        this._selectEl = this._selectEl || document.createElement('select');
        if (!this._selectEl.parentElement) {
            this._selectEl.style.display = 'none';
            this.root.appendChild(this._selectEl);
        }
        return this._selectEl;
    }

    disconnectedCallback() {
        var parent = this.parentElement;
        while (parent && parent != document.body) {
            parent = parent.parentElement;
        }
        if (!parent) {
            window.requestAnimationFrame(() => {
                this.cleanUp();
            })
        }
    }
}

OCSelect.is = "oc-select";
