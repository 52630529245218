import { Observable, Subject } from 'rxjs';
import {
  ValueChange,
  selectedFilterOptionData,
} from './table-column-filters-models';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EditableTableService {
  private changedData = new Subject<any>();

  constructor() {}

  Add(changedDataObject: ValueChange) {
    this.changedData.next(changedDataObject);
  }

  SubscribeMessages(): Observable<any> {
    return this.changedData.asObservable();
  }

  ClearMessages() {
    this.changedData.next(null);
  }
}

@Injectable()
export class FilterOpenService {
  private filterData = new Subject<any>();
  private selectedOptions = new Subject<selectedFilterOptionData>();
  private clearAllFilter = new Subject<any>();
  private selectedOption = new Subject<any>();

  constructor() {}

  setOpenFilter(filterDataObject: any) {
    this.filterData.next(filterDataObject);
  }

  getFilterStatus(): Observable<any> {
    return this.filterData.asObservable();
  }

  setLoadMoreFilterData(selectedOptionsObject: selectedFilterOptionData) {
    this.selectedOptions.next(selectedOptionsObject);
  }

  getSelectedOptions(): Observable<any> {
    return this.selectedOptions;
  }

  setClearAllFilter(clearFilterObject: any) {
    this.clearAllFilter.next(clearFilterObject);
  }

  getClearAllFilterStatus(): Observable<any> {
    return this.clearAllFilter;
  }

  setLoadFilterData(selectedOptionObject: any) {
    this.selectedOption.next(selectedOptionObject);
  }

  getLoadFilterDataStatus(): Observable<any> {
    return this.selectedOption;
  }

  ClearMessages() {
    this.filterData.next(null);
  }
}
