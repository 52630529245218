import Pagination from 'zsui/src/pagination/pagination.m';



export default class CustomPagination extends Pagination {
   
    constructor(...args) {
        const _ = super(...args);
        return _;
    }

    setup() {
        super.setup();
        this.syncProp('pageSizeForDropdown');
    }

    page(index, currentPage, totalPages, unknown) {
        var i, html = '';
        currentPage = currentPage || this.currentPage;
        totalPages = totalPages || this.totalPages;
        unknown = unknown || this.UNKNOWN;
        if (index == currentPage) {
            if (totalPages >= this.pageSizeForDropdown && totalPages != unknown) { // Add a drop down								
                // Could avoid recreation of the element
                html = '<select>'
                for (i = 1; i <= totalPages; i++) {
                    html += '<option value="' + i + '"' + (i == currentPage ? ' selected' : '') + '>' + i + '</option>'
                }
                html += '</select>';

                return '<span current="" page="' + index + '">' + html + '</span>';

            } else {
                return '<a page="' + index + '" current="">' + index + '</a>';
            }
        }
        return '<a page="' + index + '">' + index + '</a>';
    }

    static get observedAttributes() {
        return (super.observedAttributes || []).concat(['items-count', 'page-size', 'current-page', 'max-display-pages', 'onpagechange', 'onbeforechange', 'mode', 'page-size-for-dropdown']);
    }

    _setDefaults() {
        this.UNKNOWN = 9007199254740991;
        this._itemsCount = 245;
        this._pageSize = 15;
        this._currentPage = 1;
        this._maxDisplayPages = 5;
        this._mode = 'action';
        this._pageSizeForDropdown = 25;
    }


    getPropertyType(name) {
        switch (name) {
            case 'items-count':
            case 'page-size':
            case 'current-page':
            case 'max-display-pages':
            case 'page-size-for-dropdown':
                return 'number'
            case 'onpagechange':
            case 'onbeforechange':
                return 'function'
            default:
                return 'string';
        }
    }

    propertyChangedCallback(name, oldValue, newValue) {
        this.mode === 'classic' ? console.warn("Classic mode has been deprecated since v5.0 and shall be removed in future release. Please use 'action' mode instead.") : null;

        super.propertyChangedCallback(name, oldValue, newValue);
        if (!this.parentNode && !this.innerHTML) {
            return;
        }
        if (name == 'itemsCount' || name == 'pageSize' || name == 'currentPage' || name == 'maxDisplayPages' || name == 'pageSizeForDropdown') {
            if (this._renderDebounce) {
                window.cancelAnimationFrame(this._renderDebounce);
                delete this._renderDebounce;
            }
            var self = this;
            this._renderDebounce = window.requestAnimationFrame(function () {
                self.render();
                delete self._renderDebounce;
            });
        }
    }
}
